var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{attrs:{"id":"modal-medicine-order","size":"xl","title":"Pilih Obat","hide-footer":""}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-9"},[_c('Table',{attrs:{"purpose":"modal","modalPurpose":"takeaway","isHospitalTransaction":_vm.isHospitalTransaction,"selectedItem":_vm.selectedItem,"checkbox":_vm.checkbox,"isSortcut":false,"isAbjad":true,"secondPurpose":_vm.secondPurpose},on:{"chooseMedicineOrder":_vm.chosenMedicineOrder,"packageMedicineOrder":_vm.packageMedicineOrder}})],1),_c('div',{staticClass:"col-md-3"},[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.selectedMedicine,"fields":_vm.fields,"responsive":""},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({
                  width:
                    field.key === 'actions'
                      ? _vm.purpose == 'master'
                        ? '17%'
                        : ''
                      : '',
                  width: field.key == 'generic_name' ? '15%' : '',
                })})})}},{key:"cell(name)",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.item.name)+" "),_c('b',[_vm._v("("+_vm._s(data.item.quantity)+")")])])]}},{key:"cell(actions)",fn:function(data){return [_c('b-button',{staticClass:"btn-danger mr-1",staticStyle:{"padding":"5px !important"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.deleteData(data.item.id)}}},[_c('i',{staticClass:"fas fa-trash px-0"})])]}}])})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }